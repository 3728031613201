import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import { Helmet } from 'react-helmet';




class LightBox extends Component {
  state = {
    showLightBox: false,
    selectedImage: 0,
  }

  componentDidMount = () => {
    window.addEventListener('keyup', this.handleKeyUp, false)
  }

  componentWillUnmount = () => {
    window.removeEventListener('keyup', this.handleKeyUp, false)
  }

  handleClick = (e, index) => {
    e.preventDefault()
    this.setState({ showLightBox: !this.state.showLightBox, selectedImage: index })
  }

  closeModal = () => {
    this.setState({ showLightBox: false })
  }

  goBack = () => {
    this.setState({ selectedImage: this.state.selectedImage - 1 })
  }

  goForward = () => {
    this.setState({ selectedImage: this.state.selectedImage + 1 })
  }

  handleKeyUp = e => {
    e.preventDefault()
    const { keyCode } = e
    if (this.state.showLightBox) {
      if (keyCode === 37) {
        // Left Arrow Key
        if (this.state.selectedImage > 0) {
          this.setState({ selectedImage: this.state.selectedImage - 1 })
        }
      }
      if (keyCode === 39) {
        // Right Arrow Key
        if (this.state.selectedImage < this.props.images.length - 1) {
          this.setState({ selectedImage: this.state.selectedImage + 1 })
        }
      }
      if (keyCode === 27) {
        // Escape key
        this.setState({ showLightBox: false })
      }
    }
  }

  render() {
    //const { images } = this.props
    const { showLightBox, selectedImage } = this.state;

    var images = this.props.images.reduce(function(result, img) {
        if ( [
            'CLIENTE-ANGELO-CONTARDI-COMERCIO-DE-VIDROS-LTDA.jpg',
            'CLIENTE-HAMAMOTO-CIA-LTDA.jpg',
            'CLIENTE-NISHIHARA-SAKAMOTO-LTDA.jpg',
            'CLIENTE-COMERCIO-DE-GAS-CAMARGO-LTDA.jpg',
            'CLIENTE-ALLTOS-BIKE-STORE-IMPORTACAO-E-EXPORTACAO-LTDA.jpg',
            'CLIENTE-AILTON-LOPES-OIERI-MARIA-INES-FERRAZIM-OIERI.jpg',
            'CLIENTE-RITA-DE-CASSIA-GASPARINI-DE-BRITO.jpg',
        ].indexOf( img.node.fluid.src.split(/(\\|\/)/g).pop() ) !== -1 ) {
          result.push(img);
        }
        return result;
      }, []);

    return (
      <>
        <div className="gallery">
          {images.map(
              (img, i) => (
                    <div className="galleryitem" key={img.node.fluid.src} data-aos="fade-in" data-aos-duration="1000">
                        <div className="galleryitem__inner">
                            <a href={img.node.fluid.src} alt="" onClick={e => this.handleClick(e, i)}>
                                <Img className="styledimg" alt="" fluid={img.node.fluid} />
                            </a>
                        </div>
                    </div>
                )
        )}
        </div>

        {(showLightBox) && (
            <Helmet>
                <body className="showlightbox" />
            </Helmet>
        )}

        <div className={(!showLightBox) ? `lightboxmodal` : `lightboxmodal lightboxmodal-open`} onKeyUp={e => this.handleKeyDown(e)} role="presentation">

            <div className="lightboxcontent">

                <Img fluid={images[selectedImage].node.fluid} />

                <div className="controls">
                    <button className="lightbox-nav-button lightbox-nav-button-close ion icon-close" onClick={this.closeModal}>Fechar</button>
                    <div className="leftright">
                        <button className="lightbox-nav-button lightbox-nav-button-left ion chevron-left" onClick={this.goBack} disabled={selectedImage === 0}>Anterior</button>
                        <button className="lightbox-nav-button lightbox-nav-button-right ion chevron-right" onClick={this.goForward} disabled={selectedImage === images.length - 1}>Próximo</button>
                    </div>
                </div>

            </div>

        </div>

      </>
    )
  }
}

LightBox.propTypes = {
  images: PropTypes.array.isRequired,
}

export default LightBox;