import React, { useEffect, memo/* , useState */ } from "react";

import SEO from "../components/layout/seo";
import SubHeader from "../components/layout/subheader";

import Space from "../components/space";
import LightBox from "../components/lightbox";

import Aos from 'aos';

import { graphql } from 'gatsby'; // useStaticQuery




const onFocus = () =>
{
    //console.log('PaginaNossosClientes:','onFocus');
    Aos.refresh();
}

const TempPaginaNossosClientes = ({data}) =>
{
    useEffect(() =>
    {
        //
        // componentDidMount

        //console.log('PaginaNossosClientes:','render');

        if ( typeof Aos !== `undefined` && typeof window !== `undefined` ) {
            setTimeout(function() {
                //Aos.refresh();
                Aos.init();
            }, 500);
            window.addEventListener("focus", onFocus);
        }

        //
        // componentWilUnmount

        return () => {
            //console.log('PaginaNossosClientes:','unmount');

            if ( typeof Aos !== `undefined` && typeof window !== `undefined` )
            {
                window.removeEventListener("focus", onFocus);
                //Aos.disable();
            }
        };
    }, []);

    return (
        <>
            <SEO title="Nossos clientes" />
            <SubHeader pagename="Nossos clientes" bg="page-bg-nossos-clientes.jpg" />

            <div style={{marginBottom: 100}}></div>

            <div className="page__body page__privacy">
                <div className="page__content">
                    <p data-aos="fade-in" data-aos-duration="1000">Em nossa trajetória, nos orgulhamos por ter conquistado grandes clientes e parceiros de variadas áreas de atuação. Em nossa relação com clientes, não visamos somente nosso compromisso com o negócio do cliente, mas sim com os resultados a serem alcançados. Nosso maior objetivo é manter nossos clientes satisfeitos e buscar criar e manter relacionamentos de longo prazo, baseado na integridade e comprometimento, sempre atendendo suas necessidades.</p>
                    {/* <p data-aos="fade-in" data-aos-duration="1000">Conheça alguns de nossos principais clientes:</p> */}
                    <Space altura="25" />
                    <h2>Conheça alguns de nossos principais clientes:</h2>
                    <Space altura="15" />
                    <LightBox images={data.allImageSharp.edges} />
                </div>
            </div>

            <Space altura="50" />

            <div style={{marginBottom: 100}}></div>

            {/* <div style={{marginBottom: 100}}></div>
            <div className="page__body page__privacy">
                <div className="page__content">

                    <div data-aos="fade-in" data-aos-duration="800" className="homeaccordion__item">
                        <div className="homeaccordion__item__name" role="presentation">
                            <div className="ion android-arrow-dropdown-circle homeaccordion--arrow"></div>
                            <h2>Em breve</h2>
                        </div>
                        <div className="homeaccordion__item__text">Em breve</div>
                    </div>

                    <Space altura="20" />

                    <p className="link" data-aos="fade-in" data-aos-duration="1000"><Link to="/politica-de-privacidade/">Veja também a nossa política de privacidade</Link></p>
                </div>
            </div>
            <div style={{marginBottom: 100}}></div> */}
        </>
    )
}

const PaginaNossosClientes = memo(TempPaginaNossosClientes);

export default PaginaNossosClientes;

export const pageQuery = graphql`
  query PaginaNossosClientes {
    allImageSharp {
      edges {
        node {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`